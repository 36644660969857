export default function (id, type) {
  let window = id ? `banner.${id}` : 'banner.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Banner' + (id ? ` #${id}` : ''),
    width: '500',
    height: '80%',
    minHeight: '500px',
    maxHeight: '500px',
    backdrop: true,
    move: false,
    clickOutside: false,
    windowClass: 'erp-window-modern',
    contentClass: '',
    props: {
      id: id,
      type
    }
  }, () => import('./Banner.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          console.log('Atualizado: ', val)
          this.load()
        }
      })
    }) // return wid
}
