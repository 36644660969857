<template>
  <e-row>
    <e-col>
      <a v-if="banner.url && banner.url !== ''" :href="banner.url" target="_blank"><img :src="banner.image" /></a>
      <img :src="banner.image" v-else />
      <e-row mr>
        <!--<e-col style="min-width: 50%"><u-input prefix="URL:" v-model="banner.url" /></e-col>-->
        <e-col style="min-width: 30%">
          <u-input
                  type="number"
                  hide-underline prefix="Posição:"
                  v-model="banner.position"
                  @keyup="$emit('changeBannerPosition', {id: banner.id, position: $event.target.value})"
          />
          <div v-if="banner.secao">Seção: {{banner.secao}}</div>
        </e-col>
        <e-col class="flex items-center justify-end">
          <u-btn color="grey" round size="xs" label="Editar" icon="eye" icon-type="fa" @click="$emit('edit', banner.id)" />
          <u-btn class="m-l-xs" color="negative" round size="xs" label="Deletar" icon="trash" icon-type="fa" @click="$emit('delete', banner.id)"/>
        </e-col>
      </e-row>
    </e-col>
  </e-row>
</template>

<script>
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import {UInput} from 'uloc-vue'
export default {
  name: 'BannerItem',
  props: ['banner'],
  components: {ECol, ERow, UInput}
}
</script>

<style scoped>

</style>
