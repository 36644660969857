<script>
  import BannerItem from './components/Item';
  import windowBanner from './window/windowBanner'
  import {list, changePosition, deleteBanner} from '../../../../domain/banner/services'
  import {debounce} from 'uloc-vue'
  import {types} from '../../../../domain/banner/helpers'

  export default {
    name: 'Banners',
    components: {
      BannerItem
    },
    data () {
      return {
        banners: [],
        type: 'banner',
        loading: false
      }
    },
    computed: {},
    mounted () {
      this.load()
    },
    updated () {
      if (this.$route.query.type && this.$route.query.type === 'popups') {
        this.type = 'popup'
      } else{
        this.type = 'banner'
      }
      // this.load && this.load()
    },
    watch: {
      '$route.query' (v){
        if (v.type && v.type === 'popups') {
          this.type = 'popup'
        } else{
          this.type = 'banner'
        }
        this.load && this.load()
      }
    },
    methods: {
      load () {
        this.banners = []
        this.loading = true
        list(1000, 1, `&type=${types[this.type]}`)
                .then(result => {
                  this.banners = result.data.result.map(banner => {
                    banner.image = banner.image?.full?.url
                    return banner
                  })
                  console.log(result)
                  this.loading = false
                })
                .catch(error => {
                  console.log(error)
                  this.loading = false
                })
      },
      changeBannerPosition: debounce(function ({id, position}) {
        position = parseInt(position)
        if (isNaN(position)) {
          return
        }

        changePosition(id, {position})
                .then(result => {
                  console.log(result)
                })
                .catch(error => {
                  alert('Erro ao alterar a posição do banner')
                })
      }, 300),
      removeBanner (id) {
        this.$uloc.dialog({
          title: `Tem certeza que deseja excluir esse banner?`,
          message: 'O banner será excluído do sistema e não aparecerá mais no site',
          cancel: true,
          color: 'negative'
        }).then(data => {
          this.$uloc.notify({
            color: 'negative',
            message: `Excluindo banner...`
          })
          deleteBanner(id)
                  .then(result => {
                    const bannerIndex = this.banners.findIndex(el => el.id === id)
                    this.$uloc.notify({
                      color: 'positive',
                      message: `Banner deletado com sucesso!`
                    })
                    this.banners.splice(bannerIndex, 1)
                  })
                  .catch(error => {
                    alert('Erro ao excluir o banner')
                    this.alertApiError(error)
                  })
        }).catch(() => {
        })
      },
      openWindowBanner (id = null) {
        console.log(id)
        this.windowBanner(id, this.type)
      },
      windowBanner
    },
    meta: {
      title: 'Banners',
      name: 'Banners'
    }
  }
</script>

<template>
  <div>
    <div class="flex no-wrap">
      <div class="col-grow" v-if="loading">
        <h4>Carregando...</h4>
      </div>
      <div v-else-if="type === 'popup'" class="col-grow">
        <h2>Popups</h2>
        <p>Configure os popups do website.</p>
      </div>
      <div v-else class="col-grow">
        <h2>Banners</h2>
        <p>Configure os banners do website. Recomendamos que o banner tenha no máximo 1920px de largura e no máximo 5
          banners no site. Muitos banners pode prejudicar o desempenho do carregamento do site.</p>
      </div>
      <div class="flex items-center m-l justify-end" style="min-width: 160px" v-if="!loading">
        <u-btn :label="'Novo ' + (type === 'popup' ? ' Popup' : 'Banner')" no-caps icon="plus" icon-type="fa" color="green" @click="openWindowBanner(null)"/>
      </div>
    </div>

    <banner-item class="m-t m-b-lg"
                 v-for="banner in banners"
                 :key="banner.id"
                 :banner="banner"
                 @edit="openWindowBanner"
                 @changeBannerPosition="changeBannerPosition"
                 @delete="removeBanner"
    />

  </div>
</template>
